<template>

<div>
    <div class=" mx-2 mx-md-3 mx-lg-4 p-4 w-auto w-md-auto h-auto  rounded-lg fade-in text-start" v-if="!loading">
        <!-- ModaL Registro -->
        <div class="modal fade" id="registroModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"  >
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Registrar Empresa</h5>
                    <button ref="closeButton" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="actualizarListFecha()"></button>
                </div>
                <div class="modal-body">
                    <RegisterEmpresaComponent @registro-completado="cerrarModal"></RegisterEmpresaComponent>
                </div>
                </div>
            </div>
        </div>
        <!-- ModaL Update -->
        <div class="modal fade" id="updateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"  >
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel2">Actualizar Empresa</h5>
                    <button ref="closeButton2" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="actualizarListFecha() "></button>
                </div>
                <div class="modal-body">
                    <UpdateEmpresaComponent @update-completado="cerrarModal2" v-if="authStore.empresaEdit"></UpdateEmpresaComponent>
                </div>
                </div>
            </div>
        </div>
        <!-- FILTROS -->
        <div class="d-flex flex-wrap flex-lg-nowrap justify-content-start gap-3">
            <div class="input-group w-auto">
                <span class="input-group-text"  id="basic-addon1">Razón Social</span>
                <input type="text" class="form-control" v-model="razon_social" placeholder="Ingrese una Razón Social " >
            </div>

            <div class="input-group w-auto">
                <span class="input-group-text" id="basic-addon1">RUC</span>
                <input type="text" class="form-control"  v-model="ruc" placeholder="Ingrese un RUC"  >
            </div>
            <div class="d-flex flex-nowrap ms-auto" role="group">
                <button type="button" class="btn btn-success ms-auto me-3"  style="white-space: nowrap;" data-bs-toggle="modal" data-bs-target="#registroModal">
                    Registrar Empresa
                </button>
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-bs-toggle="dropdown">
                    {{selectedSize}}
                </button>
                <div class="dropdown-menu" style="width: auto;min-width: 0;" >
                    <a  @click="selectedSize= reg" v-for="reg in cantReg" :key="reg" class="dropdown-item" href="#">{{ reg }}</a>
                    </div>
            </div>
            
        </div>
    
        <nav class="mt-3 d-flex justify-content-center fade-in" v-show= "empresas.length!=0">
            <ul class="pagination ">
                <li class="page-item">
                <a href='#' class="page-link" @click="previousPage" v-show= "empresas.length!=0" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-for="pag in totalPages" :key="pag" :class="{ active: pag === page }"><a class="page-link" @click="page=pag" href="#">{{pag}}</a></li>
                <li class="page-item">
                <a href='#' class="page-link" @click="nextPage" v-show= "empresas.length!=0" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
        <!-- TABLA -->
        <div class="table-responsive text-center">
            <div class="fw-italic fw-light mb-1">Registros: {{empresasFiltrados.length}}</div>
            <table class="table table-bordered table-hover  overflow-x:auto">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Razón Social</th>
                <th scope="col">RUC</th>
                <th scope="col">Dirección</th>
                <th scope="col">Usuarios</th>
                <th scope="col">Logs</th>
                <th scope="col">Fecha Creación</th>
                <th scope="col">Gestionar</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(empresa, index) in empresasPaginados" :key="index" >
                <th scope="row">{{ index + 1 + bottom}}</th>
                <td :class="{ 'text-truncate': !isHovered }"
                    @mouseenter="isHovered = true"
                    @mouseleave="isHovered = false"
                    style="max-width: 11rem; overflow-wrap: break-word;">{{ empresa.razon_social }}
                    </td>
                <td>{{ empresa.ruc }}</td>
                <td>{{ empresa.direccion }}</td>
                <td>
                    <button class= "btn btn-primary btn-sm " @click="authStore.razon_social=empresa.razon_social;irUsuarios(empresa)">Ver Usuarios</button>
                </td>
                <td>
                    <button class= "btn btn-primary btn-sm " @click="authStore.razon_social=empresa.razon_social;irLogs(empresa)">Ver Logs</button>
                </td>
                <td>{{ (new Date(empresa.created_at)).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' }) }}</td>
                <td class="d-flex flex-nowrap"> 
                    <button @click="authStore.setEmpresa(empresa)" data-bs-toggle="modal" data-bs-target="#updateModal" class="btn btn-outline-warning btn-sm me-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M11.013 1.427a1.75 1.75 0 0 1 2.474 0l1.086 1.086a1.75 1.75 0 0 1 0 2.474l-8.61 8.61c-.21.21-.47.364-.756.445l-3.251.93a.75.75 0 0 1-.927-.928l.929-3.25c.081-.286.235-.547.445-.758l8.61-8.61Zm.176 4.823L9.75 4.81l-6.286 6.287a.253.253 0 0 0-.064.108l-.558 1.953 1.953-.558a.253.253 0 0 0 .108-.064Zm1.238-3.763a.25.25 0 0 0-.354 0L10.811 3.75l1.439 1.44 1.263-1.263a.25.25 0 0 0 0-.354Z"></path></svg></button>
                    <button @click="async()=>{await authStore.handleEmpresaDelete(empresa.id);actualizarListFecha()}" class="btn btn-outline-danger btn-sm ms-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12"><path d="M2.22 2.22a.749.749 0 0 1 1.06 0L6 4.939 8.72 2.22a.749.749 0 1 1 1.06 1.06L7.061 6 9.78 8.72a.749.749 0 1 1-1.06 1.06L6 7.061 3.28 9.78a.749.749 0 1 1-1.06-1.06L4.939 6 2.22 3.28a.749.749 0 0 1 0-1.06Z"></path></svg></button>

                </td>
                </tr>
            </tbody>
            
            </table>
        </div>
        <!-- MENSAJE VACIO -->
        <h3 v-if="empresasPaginados.length==0 && !loading" class= " display-7 text-muted bg-light text-center">
        Aun no se tienen registros
        </h3>
        <nav class="mt-3 d-flex justify-content-center" v-if= "empresas.length!=0">
            <ul class="pagination ">
                <li class="page-item">
                <a href='#' class="page-link" @click="previousPage" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-for="pag in totalPages" :key="pag" :class="{ active: pag === page }"><a class="page-link" @click="page=pag" href="#">{{pag}}</a></li>
                <li class="page-item">
                <a href='#' class="page-link" @click="nextPage" >
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
    </div>
        <div v-if="loading" class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
</div>
</template>

<script >
import {ref,watch,onMounted,computed} from 'vue'
import axios from 'axios'
import {useAuthStore} from '../stores/auth'
import router from '../router/index';
import RegisterEmpresaComponent from './RegisterEmpresaComponent.vue'
import UpdateEmpresaComponent from './UpdateEmpresaComponent.vue'
export default {
    components:{
        RegisterEmpresaComponent,
        UpdateEmpresaComponent
    },
    setup(){
        const loading=ref(false);
        let totalPages=0
        let selectedSize=ref(25);
        let page=ref(1);
        let bottom=ref(0);
        const cantReg=[25,50,75,100,200];
        let top=ref(cantReg[0]);

        const empresas = ref([]);
        const razon_social=ref('');
        const ruc=ref('');
        
        
        watch(page, () => {
            top.value=selectedSize.value*page.value
            bottom.value=top.value-selectedSize.value
        });
        watch(selectedSize,()=>{
            page.value=1
            bottom.value=0
            top.value=selectedSize.value
        })
        const actualizarListFecha = async () =>{
            loading.value=true;
            empresas.value=[]
            try {
                let response;
                if(authStore.user.rol==4){
                    response = await axios.get(`/api/getAllEmpresas`);
                }
                if(authStore.user.rol==3){
                    response = await axios.get(`/api/getEmpresasByProveedor/${authStore.user.id}`);
                }
                empresas.value = response.data;
                totalPages.value=Math.ceil(empresas.value.length / selectedSize.value);
                } 
            catch (error) {
                console.error('Error al obtener los datos:', error);
            }
            finally{
                loading.value=false;
            }
        }
        onMounted(async () => {
            loading.value=true
            try {
                await authStore.getUser();
                if(!authStore.user){
                    router.push('/')
                }
                actualizarListFecha();
            } catch (error) {
                console.error("Error during onMounted execution:", error);
            }
        });
        //FILTRAR LOGS CON CAMPOS INGRESADOS
        const empresasFiltrados = computed(() => {
            return empresas.value.filter(empresa => empresa.razon_social.toLowerCase().includes(razon_social.value.toLocaleLowerCase())).filter(empresa => empresa.ruc.includes(ruc.value))
            
            
        });
        const empresasPaginados = computed(() => {
            //return empresasFiltrados.value.slice(bottom.value, top.value);
            return empresasFiltrados.value;
        });
        totalPages = computed(() => Math.ceil(empresasFiltrados.value.length / selectedSize.value));
        function previousPage (){
            if (page.value > 1) {
                page.value--;
                }
        }
        function nextPage (){
            if (page.value < totalPages.value) {
                page.value++;
            }
        }
        const authStore=useAuthStore();
        function irUsuarios(empresa){
            authStore.razon_social=empresa.razon_social
            router.push("/Users");
        }
        function irLogs(empresa){
            authStore.razon_social=empresa.razon_social
            router.push("/Logs");
        }
        let isHovered=ref(false);
        const closeButton = ref(null);
        const closeButton2 = ref(null);
        const cerrarModal = () => {
            if (closeButton.value) {
                closeButton.value.click();
            }
        };
        const cerrarModal2 = () => {
            if (closeButton2.value) {
                closeButton2.value.click();
            }
        };
        return {
            closeButton2,
            cerrarModal2,
            irLogs,
            irUsuarios,
            previousPage,
            nextPage,
            selectedSize,
            top,
            actualizarListFecha,
            empresasFiltrados,
            empresas,
            cantReg,
            bottom,
            totalPages,
            empresasPaginados,
            page,
            authStore,
            loading,
            razon_social,
            ruc,
            isHovered,
            cerrarModal,
            closeButton
        }
    }
}
</script>

<style>

</style>