<template>
<div>


  <div v-if="authStore.loading" class="spinner-border m-5" role="status">
      <span class="visually-hidden">Loading...</span>
  </div>
    <!-- ====== Forms Section Start -->
  <div v-if="!registroFinalizado&&authStore.user && !authStore.loading">
              
              <form @submit.prevent="registrar()" class="d-flex flex-column w-100 justify-content-center align-items-center">
                <div class=" mb-2 text-start">
                  <span class="fw-light ms-1">Nombres:</span>
                  <!-- INPUT NOMBRES -->
                  <input type="text" placeholder="" v-model="form.nombre" class="form-control me-5"/>
                  <small><span v-for="error in v$.form.nombre.$errors" :key="error.$uid" class="text-danger fw-light" v-html="error.$message"></span></small>
                </div>

                <div class=" mb-2 text-start">
                  <span class="fw-light ms-1">Apellidos:</span>
                  <!-- INPUT APELLIDOS -->
                  <input
                    type="text"
                    placeholder=""
                    v-model="form.apellido"
                    class="
                      form-control me-5
                    "
                  />
                  <small><span v-for="error in v$.form.apellido.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                </div>
                <!-- INPUT EMAIL -->
                <div class=" mb-2 text-start">
                  
                  <span class="fw-light ms-1">Email:</span>
                  <input
                    type="email"
                    placeholder="ejemplo@gmail.com"
                    v-model="form.email"
                    class="
                      form-control me-5
                    "
                  />
                  <small><span v-for="error in v$.form.email.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                </div>
                <!-- INPUT IDJC -->
                
                  <div v-if="authStore.user.rol>=3" class="mb-2 text-start" style="min-width:17em" >
                    <span class="fw-light ms-1 ">IDJC:</span>
                    <select  class="form-select text-muted" v-model="idjc">
                      <option value="   ">Seleccione un usuario</option>
                          <!-- Generar opciones dinámicas con los usuarios filtrados -->
                          <option v-for="usuario in usuarios" :key="usuario.id" :value="usuario.id">
                            {{ usuario.usuario }}
                          </option>
                    </select>
                  </div>
                  <small><span v-for="error in v$.form.idjc.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                  <!-- 
                  <input
                    type="text"
                    placeholder="Ej: Usuario"
                    v-model="idjc"
                    class="
                      form-control me-5
                    "
                    @input="flagidjc=true"
                  />
                  <small><span v-for="error in v$.form.idjc.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                </div> -->
                <!-- SEARCH BAR IDJC -->
                <!-- <div class="row mt-2" v-if="usuariosFiltrados.length!=0 && idjc!='' && flagidjc">
                  <div class="col-sm-12 ">
                      <ul class=" w-auto list-group position-absolute overflow-scroll " style="z-index: 99999; max-height:15rem;left:7rem;">
                      <a
                          class="list-group-item "
                          v-for="usuario in usuariosFiltrados"
                          :key="usuario.id"
                          @click="usuariosSeleccionado=usuario;idjc=usuariosSeleccionado.usuario;toggleSearch1()"
                          href="#"
                      >
                          {{ usuario.usuario }}
                      </a>
                      </ul>
                  </div>
                 -->
                  
                <!-- VISTA SUPERUSUARIO -->
                <div class="mb-2 text-start" style="min-width:17em" v-if="authStore.user.rol==4">
                  <span class="fw-light ms-1 ">Rol:</span>
                  <select  class="form-select text-muted" v-model="form.rol">
                    <option value="1">Usuario</option>
                    <option value="2">Administrador</option>
                    <option value="3">Proveedor</option>
                    <option value="4">SuperUsuario</option>
                  </select>
                </div>
                <!-- VISTA PROVEEDOR -->
                <div class="mb-2 text-start" style="min-width:17em" v-if="authStore.user.rol==3">
                  <span class="fw-light ms-1 ">Rol:</span>
                  <select  class="form-select text-muted" v-model="form.rol">
                    <option value="1">Usuario</option>
                    <option value="2">Administrador</option>
                  </select>
                </div>
                
                  <small><span v-for="error in v$.form.rol.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                
                <!-- INPUT EMPRESA -->
                <!-- Empresa search bar -->   
            <div class="mb-2 text-start" style="min-width:13.8em" v-if="authStore.user && authStore.user.rol>=3">
              <span class="fw-light ms-1 ">Empresa:</span>
                        <input
                        class="form-control text-start me-5"
                        placeholder="Empresa"
                        v-model="razon_social"
                        @input="flag=true"
                        />
                        <small><span v-for="error in v$.form.empresa_fid.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                <!-- SEARCH BAR EMPRESA -->
                <div class="row mt-2" v-if="empresasFiltradas.length!=0 && razon_social!='' && flag">
                  <div class="col-md-12 ">
                      <ul class=" w-auto list-group position-absolute overflow-scroll " style="z-index: 99999; max-height:15rem;">
                      <a
                          class="list-group-item "
                          v-for="empresa in empresasFiltradas"
                          :key="empresa.id"
                          @click="empresaSeleccionada=empresa;razon_social=empresaSeleccionada.razon_social;toggleSearch()"
                          href="#"
                      >
                          {{ empresa.razon_social }}
                      </a>
                      </ul>
                  </div>
                </div>
            </div>
                <!-- INPUT CONTRASEÑA -->
                <input v-show="false" type="text">
                <div class=" mb-3 text-start">
                  <span class="fw-light ms-1 ">Contraseña:</span>
                  <input
                    type="password"
                    placeholder="Contraseña"
                    v-model="form.password"
                    class="
                      
                      form-control me-5
                    "
                  />
                  <small><span v-for="error in v$.form.password.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                  <input
                    type="password"
                    placeholder="Confirmar Contraseña"
                    v-model="form.password_confirmation"
                    class="
                      
                      form-control me-5 mt-2
                    "
                  />
                  <small><span v-for="error in v$.form.password_confirmation.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                </div>
                <div class="mb-3">
                  <button
                    type="submit"
                    class="btn btn-primary
                    "
                    :disabled="authStore.someLoading"
                  >
                    Registrar
                  </button>
                </div>
              </form>
            </div>
</div>
</template>

<script>
import { ref, onMounted,computed,watch} from "vue";
import 'vue3-toastify/dist/index.css'
import { useAuthStore } from "../stores/auth";
import router from '../router/index'
import {useVuelidate} from '@vuelidate/core';
import { required$,email$,sameAs$,numeric$} from './validations.js';
import axios from 'axios'
export default {
    setup(props,{emit}){
      const razon_social=ref('');
      const idjc=ref('');
      const empresaSeleccionada=ref('');
      const usuariosSeleccionado=ref('');
      const empresas=ref([]);
      const usuarios=ref([]);
      const authStore= useAuthStore();
        const form = ref({
            nombre: "",
            apellido: "",
            idjc:"",
            email: "",
            rol:1,
            empresa_fid:"",
            password: "",
            password_confirmation:"",
            id_user: ""
        });
        let registroFinalizado=false;
        
        async function registrar(){
          
          
          
          
          if(authStore.user.rol==2)form.value.idjc=authStore.user.idjc
          else{form.value.idjc=idjc.value}
          form.value.email=form.value.email.toLowerCase()
          await v$.value.form.$validate();
          if(!v$.value.$error){
            if(flag.value){
              authStore.piniaShowErrorToast("Seleccione una empresa")
            }
            else{
              try {
                form.value.id_user=authStore.user.id
                
                const resultado = await authStore.handleRegister(form.value);
                if(resultado){
                  
                  registroFinalizado=true;
                  form.value={}
                  form.value.rol=1
                  razon_social.value=''
                  idjc.value='';
                  v$.value.form.$reset()
                  emit('registrado');
                }
                
              } catch (error) {
                console.log(error)

              }
            }
          }
          else{
            console.log(v$.value.$errors)
          }
          }
        const empresasFiltradas=computed(()=>{
            return empresas.value.filter(empresa=>empresa.razon_social.toLowerCase().includes(razon_social.value.toLowerCase()))
            
        });
        const usuariosFiltrados=computed(()=>{
            return usuarios.value.filter(usuario=>usuario.usuario.toLowerCase().includes(idjc.value.toLowerCase()))
            
        });
        watch(empresaSeleccionada,()=>{
          //razon_social.value=empresaSeleccionada.value.razon_social;
          form.value.empresa_fid=empresaSeleccionada.value.id;
        })
        watch(usuariosSeleccionado,()=>{
          //razon_social.value=empresaSeleccionada.value.razon_social;
          form.value.idjc=usuariosSeleccionado.value.id;
        })
        onMounted(async() => {
            await authStore.getUser();
            if(!authStore.user){
              router.push('/')
            }
            try {
                if(authStore.user.rol==4){
                    const response = await axios.get('/api/getAllEmpresas');
                    empresas.value = response.data;
                    const response1= await axios.get('/api/getAllu_usuarios')
                    usuarios.value = response1.data;
                }
                else if(authStore.user.rol==3){
                    const response = await axios.get(`/api/getEmpresasByProveedor/${authStore.user.id}`);
                    empresas.value = response.data;
                    const response1= await axios.get('/api/getAllu_usuarios')
                    usuarios.value = response1.data;
                    // empresaSeleccionada.value=empresas.value[0];
                    // razon_social.value=empresaSeleccionada.value.razon_social;
                }
                else{
                    empresas.value[0]=authStore.user.empresa;
                    form.value.empresa_fid=authStore.user.empresa.id
                    empresaSeleccionada.value=authStore.user.empresa;
                    razon_social.value=empresaSeleccionada.value.razon_social;
                }
            } catch (error) {
                console.error('Error fetching empresas:', error);
            }
        });
        const rules = computed(()=>{
          return{
           form:{
            nombre: {required$},
            apellido: {required$},
            idjc:{required$,numeric$},
            email: {required$,email$},
            rol:{required$},
            empresa_fid:{required$},
            password: {required$},
            password_confirmation:{ sameAs$: sameAs$(form.value.password) }
           } 
          }
        })
        const v$ = useVuelidate(rules,{form})
        function isSuper(){
          if(authStore.user?.rol==3){
            return true;
          }
          else return false;
        }
        let flag=ref(false)
        let flagidjc=ref(false);
        function toggleSearch(){
          flag.value=!flag.value;
        }
        function toggleSearch1(){
          flagidjc.value=!flagidjc.value;
        }
        
        return{
          flag,
          toggleSearch,
          form,
          registrar,
          v$,
          registroFinalizado,
          isSuper,
          authStore,
          razon_social,
          empresas,
          empresasFiltradas,
          empresaSeleccionada,
          toggleSearch1,
          usuarios,
          flagidjc,
          usuariosSeleccionado,
          idjc,
          usuariosFiltrados
        }
        
    },
  name: 'RegisterComponent'
};
</script>

<style>

</style>