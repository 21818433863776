import {defineStore} from 'pinia';
import axios from 'axios';
import { showErrorToast,showSuccessToast } from '../toast';
export const useAuthStore = defineStore("auth",{
    state: ()=>({
        authUser: null,
        isLoading: false,
        loginLoading:false,
        genericLoading:false,
        razon_social: null,
        email:null,
        usuario:null,
        log: null,
        idapi:null,
        empresaEdit:null,
        userEdit:null,
    }),
    getters:{
        user:(state)=>state.authUser,
        loading:(state)=>state.isLoading,
        razon:(state)=>state.razon_social,
        getLog:(state)=>state.log,
        getIdapi:(state)=>state.idapi,
        loginLoad:(state)=>state.loginLoading,
        someLoading:(state)=>state.genericLoading,
        getEmail:(state)=>state.email,
        getUsuario:(state)=>state.usuario,
        getEmpresaEdit:(state)=>state.empresaEdit,
        getUserEdit:(state)=>state.userEdit,
    },
    actions:{
        async setIdapi(idapi){
            this.idapi=idapi;
        },
        async setLog(log) {
              this.log = log;
            },
        async setEmpresa(empresa) {
            this.empresaEdit = empresa;
            },    
        async setUser(user) {
            this.userEdit = user;
            },    
        async getToken(){
            await axios.get("/sanctum/csrf-cookie");
        },
        async getUser(){
            if(this.authUser)return null;
            try{
                this.isLoading=true;
                await this.getToken();
                const data = await axios.get("/api/user");
                this.authUser = data.data 
                
            }
            catch(error){
                console.log(error)
            }
            finally{
                this.isLoading=false;
            }
        },
        async handleLogin(form){
            try {
                
                this.loginLoading=true;
                await this.getToken()
                await axios.post('/login', {
                  email: form.email,
                  password: form.password
                });
                showSuccessToast("Inicio de sesión exitoso!")
                setTimeout(() => {(this.router.push('/'))}, 3000);
            }catch (error) {
                if(error.response.data.errors){
                    if(error.response.data.errors.email[0]=="These credentials do not match our records."){
                        showErrorToast("Credenciales inválidas. Intente nuevamente");
                    }
                }
                
                this.loginLoading=false;
            }
        },
        async handleEmpresaRegister(form){
            try {
                this.genericLoading=true;
                await this.getToken();
                if(this.user.rol==3){
                    
                    await axios.post('/api/registerProveedorEmpresa',{
                        razon_social:form.razon_social,
                        ruc:form.ruc,
                        direccion:form.direccion,
                        id_user:form.id_user
                    })
                }
                else if(this.user.rol==4){

                    await axios.post('/api/registerEmpresa',{
                        razon_social:form.razon_social,
                        ruc:form.ruc,
                        direccion:form.direccion
                    })
                }
                showSuccessToast("Empresa registrada con éxito")
                
                return true 
            } catch (error) {
                this.genericLoading=false;
                if(error.response.data.message=="The ruc has already been taken."){
                    
                    showErrorToast("Este RUC ya ha sido registrado")
                }else{
                    showErrorToast("No se pudo completar el registro")

                }
            }finally{
                
                this.genericLoading=false;
            }
        },
        async handleEmpresaUpdate(id,empresa){
            try {
                this.genericLoading=true;
                await this.getToken();
                await axios.put(`/api/updateEmpresa/${id}`,empresa)
                showSuccessToast("Empresa actualizada con éxito")
                
                return true 
            } catch (error) {
                this.genericLoading=false;
                console.log(error);
                    showErrorToast("No se pudo completar la actualización")

            }finally{
                
                this.genericLoading=false;
            }
        },
        async handleEmpresaDelete(id){
            try {
                this.genericLoading=true;
                await this.getToken();
                    
                await axios.delete(`/api/deleteEmpresa/${id}`)
                showSuccessToast("Empresa eliminada correctamente")
            
            } catch (error) {
                this.genericLoading=false;
                showErrorToast("No se pudo eliminar la empresa")
            }finally{
                this.genericLoading=false;
            }
        },
        async handleUserDelete(id){
            try {
                this.genericLoading=true;
                await this.getToken();
                    
                await axios.delete(`/api/deleteUsuario/${id}`)
                showSuccessToast("Usuario eliminado correctamente")
            } catch (error) {
                this.genericLoading=false;
                    showErrorToast("No se pudo eliminar el usuario")
            }finally{
                
                this.genericLoading=false;
            }
        },
        async handleRegister(form){
            try{
                this.genericLoading=true;
                await this.getToken();
                if(this.user.rol==3 && form.rol==2){
                    
                    await axios.post('/registerProveedorCliente',{
                        nombre: form.nombre,
                        apellido: form.apellido,
                        idjc: form.idjc,
                        email: form.email,
                        rol: form.rol,
                        empresa_fid: form.empresa_fid,
                        password: form.password,
                        password_confirmation:form.password_confirmation,
                        id_user:form.id_user
                    })
                }
                else{

                    await axios.post('/register',{
                        nombre: form.nombre,
                        apellido: form.apellido,
                        idjc: form.idjc,
                        email: form.email,
                        rol: form.rol,
                        empresa_fid: form.empresa_fid,
                        password: form.password,
                        password_confirmation:form.password_confirmation
                    })
                }

                showSuccessToast("Usuario registrado con éxito")
                this.genericLoading=true;
                return true 
            }
            catch(error){
                console.log(error)
                this.genericLoading=false;
                 if(error.response.data.errors.email){
                     if(error.response.data.errors.email[0]=="The email has already been taken."){
                         showErrorToast("Este correo electrónico ya fue registrado")

                     }

                 } 
                 if(error.response.data.errors.idjc){
                        showErrorToast(error.response.data.errors.idjc[0])
                } 
                //showErrorToast("Este correo electrónico ya fue registrado")
                console.log(error.response.data.errors.password)
                if(error.response.data.errors.password){
                    const mensajes=error.response.data?.errors.password
                    for(const index in mensajes){
                        console.log(mensajes[index])
                        showErrorToast(this.traducirError(mensajes[index]))
                    }
                }

                return false
            }
            finally{
                this.genericLoading=false;
            }
        },
        async handleUserUpdate(id,user){
            try{
                this.genericLoading=true;
                await this.getToken();
                    
                await axios.put(`api/updateUser/${id}`,user)
               
                showSuccessToast("Usuario actualizado con éxito")
                this.genericLoading=true;
                return true 
            }
            catch(error){
                console.log(error)
                this.genericLoading=false;
                 if(error.response.data.errors.email){
                     if(error.response.data.errors.email[0]=="The email has already been taken."){
                         showErrorToast("Este correo electrónico ya fue registrado")

                     }

                 } 
                 if(error.response.data.errors.idjc){
                        showErrorToast(error.response.data.errors.idjc[0])
                } 
                //showErrorToast("Este correo electrónico ya fue registrado")
                console.log(error.response.data.errors.password)
                if(error.response.data.errors.password){
                    const mensajes=error.response.data?.errors.password
                    for(const index in mensajes){
                        console.log(mensajes[index])
                        showErrorToast(this.traducirError(mensajes[index]))
                    }
                }

                return false
            }
            finally{
                this.genericLoading=false;
            }
        },
        async handleLogout(){
            
            //await this.getToken();

            try {
                await axios.post('/logout');
                this.authUser=null;
                this.log=null;
                this.razon_social=null;
                this.loginLoading=false;
                this.router.push("/login");
            } catch (error) {
                console.log(error)
            }
        },
        async handleForgot(data){
            try {
                await axios.post("/forgot-password",{email: data})
                showSuccessToast("Se ha enviado un enlace de restablecimiento a su correo electrónico")
            } catch (error) {
                if(error.response.data.errors){
                    if(error.response.data.errors.email[0]=="We can't find a user with that email address."){
                        showErrorToast("Este correo electrónico no está registrado");
                    }
                    else if(error.response.data.errors.email[0]=="Please wait before retrying."){
                        showErrorToast("Espere unos momentos hasta volver a intentarlo");
                    }
                }
            }
            
        },
        async handleReset(data){
            try {
                await axios.post('/reset-password',data)
                showSuccessToast("Contraseña restablecida con éxito")
                setTimeout(()=>{(this.router.push('/Login'))},3000)
            } catch (error) {
                if(error.response.data.errors.password){
                    const mensajes=error.response.data?.errors.password
                    for(const index in mensajes){
                        console.log(mensajes[index])
                        showErrorToast(this.traducirError(mensajes[index]))
                    }
                }
            }
        },
        async handleNotify(){
            try {
                await axios.post('/email/verification-notification',this.user)
                showSuccessToast("Enviamos un enlace de verificación a tu correo!")
            } catch (error) {
                console.log(error)
            }
        },
        async handleVerify(data){
            try {
                const response=await axios.get(`/verify-email/${data.id}/${data.hash}`)
                console.log(response)
            } catch (error) {
                console.log()
            }
        },
        traducirError(mensaje){
            if(mensaje=="The password field must be at least 8 characters.")return "La contraseña debe tener almenos 8 caracteres";
            if(mensaje=="The password field must contain at least one uppercase and one lowercase letter.")return "La contraseña debe tener almenos 1 mayúscula y 1 minúscula";
            if(mensaje=="The password field must contain at least one symbol.")return "La contraseña debe tener almenos 1 símbolo";
            if(mensaje=="The password field must contain at least one number.")return "La contraseña debe tener almenos 1 número";
  
        },
        piniaShowErrorToast(mensaje){
            showErrorToast(mensaje);
        },
        piniaShowSuccessToast(mensaje){
            showSuccessToast(mensaje);
        },
        resetStates(){
            this.razon_social=null;
        }
    }
})