<template>
<div>
    <div class=" mx-2 mx-md-3 mx-lg-4 p-4 w-auto w-md-auto h-auto  rounded-lg fade-in text-start" v-if="!loading">
        
            
        <span class="d-flex mb-2">Fecha inicio - Fecha fin:</span>

        <div class="d-flex flex-wrap flex-lg-nowrap justify-content-start gap-3">
            
            <!-- AÑO DROPDOWN -->   
            <div class="btn-group" >
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-bs-toggle="dropdown">
                {{añoSeleccionado}}
                </button>
                <div class="dropdown-menu" style="width: auto;min-width: 0; " >
                    <a v-for="(año,index) in años" :key="año" :value="index" class="dropdown-item " @click="añoSeleccionado = años[index]" href="#">{{ año }}</a>
                </div>
            </div>
            <!-- MES DROPDOWN -->
            <div class="btn-group mr-5 mr-lg-0" role="group">
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-bs-toggle="dropdown">
                    {{mesSeleccionado.nombre}}
                </button>
                <div class="dropdown-menu" style="width: auto;min-width: 0;" >
                    <a  @click="mesSeleccionado = mes" v-for="mes in meses" :key="mes" class="dropdown-item" href="#">{{ mes.nombre }}</a>
                    </div>
            </div>  
            <!-- AÑO DROPDOWNFIN -->   
            <div class="btn-group" >
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-bs-toggle="dropdown">
                {{añoFinSeleccionado}}
                </button>
                <div class="dropdown-menu" style="width: auto;min-width: 0; " >
                    <a v-for="(año,index) in años" :key="año" :value="index" class="dropdown-item " @click="añoFinSeleccionado = años[index]" href="#">{{ año }}</a>
                </div>
            </div>
            <!-- MES DROPDOWN FIN -->
            <div class="btn-group mr-5 mr-lg-0" role="group">
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-bs-toggle="dropdown">
                    {{mesFinSeleccionado.nombre}}
                </button>
                <div class="dropdown-menu" style="width: auto;min-width: 0;" >
                    <a  @click="mesFinSeleccionado = mes" v-for="mes in meses" :key="mes" class="dropdown-item" href="#">{{ mes.nombre }}</a>
                    </div>
            </div>  
            <!-- FILTRO EMPRESA -->
            <!-- <div class=" w-50 text-start" style="max-width:20em;" v-if="authStore.user && authStore.user.rol>=3 ">
                        <input
                        type="text"
                        class="form-control text-start"
                        placeholder="Empresa"
                        v-model="razon_social"
                        @input="flag=true"
                        />
                <div class="row mt-2" v-if="empresasFiltradas.length && razon_social!='' && flag">
                <div class="col-md-12 " >
                    <ul class=" w-auto list-group position-absolute overflow-scroll "  style="z-index: 50; max-height:20rem;">
                    <a
                        class="list-group-item"
                        v-for="empresa in empresasFiltradas"
                        :key="empresa.id"
                        @click="razon_social=empresa.razon_social;flag=false"
                        href="#"
                    >
                        {{ empresa.razon_social }}
                    </a>
                    </ul>
                </div>
                </div>
            </div> -->
            <!-- FORMS FILTROS -->
            <div class="input-group w-auto">
                <span class="input-group-text"  id="basic-addon1">Usuario</span>
                <input type="text" class="form-control" v-model="usuario" placeholder="Ingrese un Usuario" >
            </div> 
            <div class="input-group w-auto">
                <span class="input-group-text"  id="basic-addon1">Tipo</span>
                <input type="text" class="form-control" v-model="tipo" placeholder="Ingrese un tipo de verificación" >
            </div>
            <div class="btn-group ms-auto" role="group">
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-bs-toggle="dropdown">
                    {{selectedSize}}
                </button>
                <div class="dropdown-menu" style="width: auto;min-width: 0;" >
                    <a  @click="selectedSize= reg;actualizarListFecha()" v-for="reg in cantReg" :key="reg" class="dropdown-item" href="#">{{ reg }}</a>
                    </div>
            </div>
            
        </div>
        <!-- PAGINACIÓN -->
        <nav class="mt-3 d-flex justify-content-center" v-show= 'totalPages!=0'>
            <ul class="pagination flex-wrap">
                <li class="page-item">
                <a href='#' class="page-link" @click="previousPage" v-show= "logs.length!=0" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-for="pag in count" :key="pag" :class="{ active: pag === page }"><a class="page-link" @click="page=pag" href="#">{{pag+(maxPages-25)}}</a></li>
                <li class="page-item">
                <a href='#' class="page-link" @click="nextPage" v-show= "logs.length!=0" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
        <!-- TABLA -->
        <div class="fw-italic fw-light mb-3 w-100  d-flex ">
            <div class="my-auto">
                
                    Registros en total: {{totalRegs}}
                </div>
            <button type="button" @click="descargarExcel()" class="btn btn-success ms-auto ">
            Exportar a Excel
            </button>
        </div>
        <div class="table-responsive text-center">
            <table class="table table-bordered table-hover overflow-x:auto">
            <thead>
                <tr>
                <th scope="col">#</th>
                <!-- <th scope="col">Email</th> -->
                <th scope="col">Usuario</th>
                <!-- <th scope="col">Empresa</th> -->
                <th scope="col">Tipo</th>
                <th scope="col">Token</th>
                <th scope="col">Fecha Inicial</th>
                <th scope="col">Fecha Final</th>
                <th scope="col">Mensaje</th>
                <th scope="col">Estado envío</th>
                <!-- <th scope="col">F/H envío</th> 
                 <th scope="col">Cert. Envío</th>  -->
                <th scope="col">Estado de entrega</th>
                 <!-- <th scope="col">F/H entrega</th> 
                 <th scope="col">Cert. entrega</th>  -->
                <th scope="col">F/H verificación</th>
                <th scope="col">Estado verificación</th>
                <th scope="col">Intentos verificación</th>
                <th scope="col">Idapi</th>
                <th scope="col">Detalle</th>
                
                </tr>
            </thead>
            <tbody>
                <tr v-for="(log, index) in logsPaginados" :key="index" >
                <th scope="row">{{ index + 1 + bottom +(maxPages-25)*selectedSize}}</th>
                <!-- <td>{{ log.user?log.user.email:'No registrado' }}</td> -->
                <td>{{ log.u_usuario }}</td>
                <!-- <td :class="{ 'text-truncate': !isHovered }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    style="max-width: 11rem; overflow-wrap: break-word;">{{ log.user?log.user.empresa.razon_social:'No registrado' }}</td> -->
                <td>{{ log.tra_tipotrazabilidad }}</td>
                <td>{{ log.tra_token }}</td>
                <td>{{ log.tra_fechaini }}</td>
                <td>{{ log.tra_fechafin }}</td>
                <td>{{ log.tra_mensaje }}</td>
                <td>{{ log.tra_estado_envio }}</td>
                <!-- <td>{{ log.tra_fechahora_envio }}</td>
                <td>{{ log.tra_cert_envio }}</td> -->
                <td>{{ log.tra_estado_entrega }}</td>
                <!-- <td>{{ log.tra_fechahora_entrega }}</td>
                <td>{{ log.tra_cert_entrega }}</td> -->
                <td>{{ log.tra_fechahoraverificacion }}</td>
                <td>{{ log.tra_estadoverificacion }}</td>
                <td>{{ log.tra_intentosverificacion }}</td>
                <td :class="{ 'text-truncate': !isHovered }"
                    @mouseenter="isHovered = true"
                    @mouseleave="isHovered = false"
                    style="max-width: 11rem; overflow-wrap: break-word;">{{ log.tra_idapi }} 
                    
                    <button type="button" @click="copy(log.tra_idapi)"
                    class="btn btn-outline-secondary ms-auto btn-sm">
                            <svg v-if="texto==''" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="10" height="10"><path d="M0 6.75C0 5.784.784 5 1.75 5h1.5a.75.75 0 0 1 0 1.5h-1.5a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25v-1.5a.75.75 0 0 1 1.5 0v1.5A1.75 1.75 0 0 1 9.25 16h-7.5A1.75 1.75 0 0 1 0 14.25Z"></path><path d="M5 1.75C5 .784 5.784 0 6.75 0h7.5C15.216 0 16 .784 16 1.75v7.5A1.75 1.75 0 0 1 14.25 11h-7.5A1.75 1.75 0 0 1 5 9.25Zm1.75-.25a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25Z"></path></svg>
                    </button>
                </td>
                <!-- <td>{{ (new Date(log.tra_fechahoraverificacion)).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' }) }}</td> -->
                <td>
                    <button type="button" @click="guardarLog(log);flagDetalle=!flagDetalle" class= "btn btn-primary btn-sm " data-bs-toggle="modal" data-bs-target="#registroModal">Ver detalle</button>
                    <!-- Modal VER DETALLE-->
                </td>
                </tr>
            </tbody>
            
            </table>
                    <div class="modal fade" id="registroModal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"  >
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Detalle Log</h5>
                                <button @click="flagDetalle=!flagDetalle" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div v-if="flagDetalle" class="modal-body">
                                <DetalleComponent></DetalleComponent>
                            </div>
                            </div>
                        </div>
                    </div>
        <!-- MENSAJE VACIO -->
        <h3 v-if="logsPaginados.length==0 && !loading" class= " display-7 text-muted bg-light text-center">
        Aun no se tienen registros
        </h3>
        </div>
        <!-- PAGINADOR -->
        <nav class="mt-3 d-flex justify-content-center" v-if= "logs.length!=0">
            <ul class="pagination flex-wrap">
                <li class="page-item">
                <a href='#' class="page-link" @click="previousPage" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-for="pag in count" :key="pag" :class="{ active: pag === page }"><a class="page-link" @click="page=pag;" href="#">{{pag+(maxPages-25)}}</a></li>
                <li class="page-item">
                <a href='#' class="page-link" @click="nextPage" >
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
    </div>
        <div v-if="loading" class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
</div>
</template>

<script >
import {ref,watch,onMounted,computed} from 'vue'
import axios from 'axios'
import router from '../router/index';
import {useAuthStore} from '../stores/auth'
import DetalleComponent from '../components/DetalleComponent.vue'
import exportFromJSON from 'export-from-json';
//import router from '../router/index';
export default {
    components:{
        DetalleComponent    
    },
    setup(){
        let totalRegs=ref(0);
        let count = ref(0);
        let tipo=ref('');
        const empresas=ref([]);
        const razon_social=ref('');
        const flag=ref(false);
        const loading=ref(false);
        let totalPages=ref(0);
        let selectedSize=ref(25);
        let maxPages=ref(25);
        let page=ref(1);
        let bottom=ref(0);
        const cantReg=[25,50,75,100,200];
        let top=ref(cantReg[0]);
        const email=ref('')
        const usuario=ref('')
        const region=ref('');
        const logs = ref([]);
        const flagDetalle=ref(false);
        const años = ref([2024, 2023, 2022, 2021]);
        const añoSeleccionado = ref(años.value[0]);
        const añoFinSeleccionado = ref(años.value[0]);
        const meses = [
        { nombre: 'Enero', numero: 1 },
        { nombre: 'Febrero', numero: 2 },
        { nombre: 'Marzo', numero: 3 },
        { nombre: 'Abril', numero: 4 },
        { nombre: 'Mayo', numero: 5 },
        { nombre: 'Junio', numero: 6 },
        { nombre: 'Julio', numero: 7 },
        { nombre: 'Agosto', numero: 8 },
        { nombre: 'Septiembre', numero: 9 },
        { nombre: 'Octubre', numero: 10 },
        { nombre: 'Noviembre', numero: 11 },
        { nombre: 'Diciembre', numero: 12 }
        ];
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();    
        const mesSeleccionado = ref(meses[currentMonth]);
        const mesFinSeleccionado = ref(meses[currentMonth]);
        watch(page, () => {
            top.value=selectedSize.value*page.value
            bottom.value=top.value-selectedSize.value
        });
        watch(selectedSize,()=>{
            page.value=1
            bottom.value=0
            top.value=selectedSize.value
        })
        watch(añoSeleccionado, () => {
            if(añoSeleccionado.value>añoFinSeleccionado.value){
                authStore.piniaShowErrorToast('El año final debe ser mayor')
            }
            else if(mesSeleccionado.value.numero>mesFinSeleccionado.value.numero){
            authStore.piniaShowErrorToast('El mes final debe ser mayor')
            }
            else{
                actualizarListFecha()
            }   
        });
        watch(mesSeleccionado, () => {
                if(añoSeleccionado.value>añoFinSeleccionado.value){
                authStore.piniaShowErrorToast('El año final debe ser mayor')
            }
            else if(mesSeleccionado.value.numero>mesFinSeleccionado.value.numero){
            authStore.piniaShowErrorToast('El mes final debe ser mayor')
            }
            else{
                actualizarListFecha()
            }   
        });
        watch(añoFinSeleccionado, () => {
                if(añoSeleccionado.value>añoFinSeleccionado.value){
                authStore.piniaShowErrorToast('El año final debe ser mayor')
            }
            else if(mesSeleccionado.value.numero>mesFinSeleccionado.value.numero){
            authStore.piniaShowErrorToast('El mes final debe ser mayor')
            }
            else{
                actualizarListFecha()
            }   
        });
        watch(mesFinSeleccionado, () => {
            
                if(añoSeleccionado.value>añoFinSeleccionado.value){
                authStore.piniaShowErrorToast('El año final debe ser mayor')
            }
            else if(mesSeleccionado.value.numero>mesFinSeleccionado.value.numero){
            authStore.piniaShowErrorToast('El mes final debe ser mayor')
            }
            else{
                actualizarListFecha()
            }   
        });
        const actualizarListEmpresas= async()=>{
            console.log(añoSeleccionado)
            console.log(añoFinSeleccionado)
            console.log(mesSeleccionado)
            console.log(mesFinSeleccionado)
            loading.value=true;
            try {
                if(authStore.user&&authStore.user.rol==4){
                    const response = await axios.get('/api/getAllEmpresas');
                    empresas.value = response.data;
                    //razon_social.value=empresas.value[0].razon_social;
                }
                // else{
                //     //empresas.value[0]=authStore.user.empresa;
                //     //razon_social.value=authStore.user.empresa.razon_social;
                // }
            } catch (error) {
                console.error('Error fetching empresas:', error);
            }
            finally{
                loading.value=false;
            }
        }
        const actualizarListFecha = async () =>{
            if(añoFinSeleccionado.value>=añoSeleccionado.value && mesFinSeleccionado.value>=mesSeleccionado.value){
                loading.value=true;
                logs.value=[]
                try {
                    let response;
                    let response2;
                    if(authStore.user&&authStore.user.rol==4){
                    
                        response = await axios.get(`/api/getPeriodTrazabilidadOffset2/${añoSeleccionado.value}/${mesSeleccionado.value.numero}/${añoFinSeleccionado.value}/${mesFinSeleccionado.value.numero}/${(maxPages.value-(25))*selectedSize.value}/${selectedSize.value*25}`);
                        response2 = await axios.get(`/api/getPeriodTrazabilidadCount2/${añoSeleccionado.value}/${mesSeleccionado.value.numero}/${añoFinSeleccionado.value}/${mesFinSeleccionado.value.numero}`);
                    }
                    else if (authStore.user&&authStore.user.rol==3){
                        response = await axios.get(`/api/getProveedorPeriodTrazabilidadOffset2/${añoSeleccionado.value}/${mesSeleccionado.value.numero}/${añoFinSeleccionado.value}/${mesFinSeleccionado.value.numero}/${authStore.user.id}/${(maxPages.value-(25))*selectedSize.value}/${selectedSize.value*25}`); //Aqui se usa id por que con este id se ubican los idjc asociados
                        response2 = await axios.get(`/api/getProveedorPeriodTrazabilidadCount2/${añoSeleccionado.value}/${mesSeleccionado.value.numero}/${añoFinSeleccionado.value}/${mesFinSeleccionado.value.numero}/${authStore.user.id}`);
                    
                    }
                    else{
                        response = await axios.get(`/api/getAdminPeriodTrazabilidadOffset2/${añoSeleccionado.value}/${mesSeleccionado.value.numero}/${añoFinSeleccionado.value}/${mesFinSeleccionado.value.numero}/${authStore.user.idjc}/${(maxPages.value-(25))*selectedSize.value}/${selectedSize.value*25}`); //aqui se usa directamente el idjc asociado
                        response2 = await axios.get(`/api/getAdminPeriodTrazabilidadCount2/${añoSeleccionado.value}/${mesSeleccionado.value.numero}/${añoFinSeleccionado.value}/${mesFinSeleccionado.value.numero}/${authStore.user.idjc}`);
                    }
                    logs.value = response.data;
                    totalRegs.value=response2.data;
                    totalPages.value= Math.ceil(response2.data / selectedSize.value);
                    count.value=  Math.ceil(logs.value.length / selectedSize.value);
                
                    } 
                catch (error) {
                    console.error('Error al obtener los datos:', error);
                }
                finally{
                    loading.value=false;
                }
            }
            else authStore.piniaShowErrorToast('La fecha final debe ser mayor')
        }
        
        
        onMounted(async () => {
            
            loading.value=true
            try {
                await authStore.getUser();
                if(!authStore.user){
                    router.push('/')
                }
                actualizarListFecha();
                actualizarListEmpresas();
                    if(authStore.usuario){
                        usuario.value=authStore.usuario
                    }
                    if(authStore.razon_social){
                        razon_social.value=authStore.razon_social
                    }
            } catch (error) {
                console.error("Error during onMounted execution:", error);
            }
        });
        //FILTRAR LOGS CON CAMPOS INGRESADOS
        const logsFiltrados = computed(() => {
            return logs.value
                    .filter(log => log.tra_tipotrazabilidad?.toLowerCase().includes(tipo.value.toLowerCase()))
                    //.filter(log => log.user?.empresa?.razon_social?.toLowerCase().includes(razon_social.value.toLowerCase()))
                    .filter(log => log.u_usuario?.toLowerCase().includes(usuario.value.toLowerCase()));

            // return logs.value;
        });
        const logsPaginados = computed(() => {
            return logsFiltrados.value.slice(bottom.value, top.value);
        });
        const empresasFiltradas=computed(()=>{
            
            return empresas.value.filter(empresa=>empresa.razon_social.toLowerCase().includes(razon_social.value.toLowerCase()))
            
        });
        //totalPages = computed(() => Math.ceil(logsFiltrados.value.length / selectedSize.value));
        //totalPages = computed(() => Math.ceil(count.value / selectedSize.value));
        totalPages = computed(() => Math.ceil(totalRegs.value / selectedSize.value));
        function previousPage (){
            if(page.value==1){
                if(maxPages.value>25){
                    maxPages.value=maxPages.value-25;
                    page.value=maxPages.value;
                    actualizarListFecha();
                }
            }
            else page.value--
            //else if (page.value>count.value)page.value--;
        }
        function nextPage (){
            if(totalPages.value>maxPages.value){
                if(page.value==25){
                    maxPages.value=maxPages.value+25;
                    page.value=1;
                    actualizarListFecha();
                }
                else{
                    page.value++;
                }
            }
            else if (page.value<count.value)page.value++;
        }
        const authStore=useAuthStore();
        async function guardarLog(logParaGuardar){
            await authStore.setLog(logParaGuardar);
            
        }
        const descargarExcel=async ()=>{
            //let data=logsPaginados.value;
            let response=null;
            if(authStore.user&&authStore.user.rol==4){
                response = await axios.get(`/api/getPeriodTrazabilidadAll2/${añoSeleccionado.value}/${mesSeleccionado.value.numero}/${añoFinSeleccionado.value}/${mesFinSeleccionado.value.numero}`);
                
                }
            else if(authStore.user&&authStore.user.rol==3){
                response = await axios.get(`/api/getProveedorPeriodTrazabilidadAll2/${añoSeleccionado.value}/${mesSeleccionado.value.numero}/${añoFinSeleccionado.value}/${mesFinSeleccionado.value.numero}/${authStore.user.id}`);
            }
            else{
                response = await axios.get(`/api/getAdminPeriodTrazabilidadAll/${añoSeleccionado.value}/${mesSeleccionado.value.numero}/${añoFinSeleccionado.value}/${mesFinSeleccionado.value.numero}/${authStore.user.idjc}`);
            }
            let data = response.data.map(log => ({
        'Usuario': log.u_usuario,
        'Empresa': log.empresa_name ,
        'Tipo Trazabilidad': log.tra_tipotrazabilidad,
        'Token': log.tra_token,
        'Fecha Inicial': log.tra_fechaini,
        'Fecha Final': log.tra_fechafin,
        'Mensaje': log.tra_mensaje,
        'Estado Envío': log.tra_estado_envio,
        'Estado Entrega': log.tra_estado_entrega,
        'F/H Verificación': log.tra_fechahoraverificacion,
        'Estado Verificación': log.tra_estadoverificacion,
        'Intentos Verificación': log.tra_intentosverificacion,
        'Id API': log.tra_idapi
      }));
            let fileName  = "Logs_" + new Date().toISOString().split('T')[0];
            let exportType  = exportFromJSON.types.xls;
            exportFromJSON({data,fileName,exportType})
        }
        let isHovered=ref(false);
        const copy=(text)=>{
            const el = document.createElement('textarea');
            el.value=text;
            el.style.position = 'absolute';
            el.style = '-90000px';
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            authStore.piniaShowSuccessToast("Texto copiado!")
        }
        let texto=ref('');
        return {
            flagDetalle,
            empresasFiltradas,
            actualizarListEmpresas,
            razon_social,
            flag,
            previousPage,
            nextPage,
            selectedSize,
            top,
            años,
            meses,
            añoSeleccionado,
            mesSeleccionado,
            actualizarListFecha,
            logsFiltrados,
            logs,
            email,
            region,
            cantReg,
            bottom,
            totalPages,
            logsPaginados,
            page,
            authStore,
            loading,
            guardarLog,
            tipo,
            descargarExcel,
            usuario,
            isHovered,
            copy,
            texto,
            maxPages,
            count,
            totalRegs,
            añoFinSeleccionado,
            mesFinSeleccionado
        }
    }
}
</script>

<style>

</style>