<template>
    <div class="d-flex flex-column w-100 justify-content-center align-items-center mt-4 ">
        <form class="bg-light rounded border p-3" @submit.prevent="enviarLink()"  novalidate>
            <label for="exampleFormControlInput1" class="form-label text-muted">Recuperación de la cuenta</label>
            <input type="email" v-model="email" class="form-control " id="formControl" placeholder="tu@correo.com">
            <small><span v-for="error in v$.email.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
            <button type="submit" class="btn btn-sm btn-primary mt-3">Restablecer contraseña</button>
        </form>
    </div>
</template>

<script>
import {ref,computed} from 'vue'
import {useAuthStore} from '../stores/auth'
import {useVuelidate} from '@vuelidate/core'
import {required$,email$} from './validations'
export default {

    setup(){
        const authStore=useAuthStore();
        const email=ref('');
        async function enviarLink(){
            await v$.value.email.$validate();
            if(!v$.value.email.$error){
                await authStore.handleForgot(email.value)
            }
        }
        const rules=computed(()=>{
            return{
                email:{required$,email$}
            }
        })
        const v$= useVuelidate(rules,{email})
        return{
            email,
            authStore,
            enviarLink,
            v$
        }
    }
}
</script>

<style>

</style>