<template>
<div>
  <div v-if="authStore.loading" class="spinner-border m-5" role="status">
      <span class="visually-hidden">Loading...</span>
  </div>
    <!-- ====== Forms Section Start -->
  <div v-if="!registroFinalizado&&authStore.user && !authStore.loading">
              <form @submit.prevent="registrar()" class="d-flex flex-column w-100 justify-content-center align-items-center">
                <div class=" mb-3">
                  <!-- INPUT RAZON SOCIAL -->
                  <input type="text" placeholder="Razón Social" v-model="form.razon_social" class="form-control  me-5"/>
                  <small><span v-for="error in v$.form.razon_social.$errors" :key="error.$uid" class="text-danger fw-light" v-html="error.$message"></span></small>
                </div>

                <div class=" mb-3">
                  <!-- INPUT RUC -->
                  <input
                    type="text"
                    placeholder="RUC"
                    v-model="form.ruc"
                    class="
                      form-control me-5
                    "
                  />
                  <small><span v-for="error in v$.form.ruc.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                </div>
                <!-- INPUT DIRECCION -->
                <div class=" mb-3">
                  <input
                    type="text"
                    placeholder="Dirección"
                    v-model="form.direccion"
                    class="
                      form-control me-5
                    "
                  />
                  <small><span v-for="error in v$.form.direccion.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
                
                </div>
                
                
                <div class="mb-3">
                  <button
                    type="submit"
                    class="btn btn-primary
                    "
                    :disabled="authStore.someLoading"
                  >
                    Registrar
                  </button>
                </div>
              </form>
            </div>
  </div>
</template>

<script>
import { ref, onMounted,computed} from "vue";
import 'vue3-toastify/dist/index.css'
import { useAuthStore } from "../stores/auth";
import router from '../router/index'
import {useVuelidate} from '@vuelidate/core';
import { required$,exactLength$} from './validations.js';
export default {
    setup(props,{emit}){
      const authStore= useAuthStore();
        const form = ref({
            razon_social: "",
            ruc: "",
            direccion: "",
            id_user:"",
        });
        let registroFinalizado=false;
        
        async function registrar(){
          await v$.value.form.$validate();
          if(!v$.value.$error){
            try {
              form.value.id_user=authStore.user.id;
              const resultado = await authStore.handleEmpresaRegister(form.value);
              if(resultado){
                registroFinalizado=true;
                form.value={}
                v$.value.form.$reset()
              
                emit('registro-completado');
              }
              
            } catch (error) {
              console.log(error)

              }
            }
          }
        
        onMounted(async() => {
            await authStore.getUser();
            if(!authStore.user){
              router.push('/')
            }
        });
        const rules = computed(()=>{
          return{
           form:{
            razon_social: {required$},
            ruc: {required$,exactLength:exactLength$(11)},
            direccion: {required$},
           } 
          }
        })
        const v$ = useVuelidate(rules,{form})
        function isSuper(){
          if(authStore.user?.rol==3){
            return true;
          }
          else return false;
        }
        return{
            form,
            registrar,
            v$,
            registroFinalizado,
            isSuper,
            authStore 
        }
        
    },
  name: 'RegisterComponent'
};
</script>

<style>

</style>