<template >
   <div  class="row">
    <!--<div class="column">
        <ul class="list-group">
            <li class="list-group-item">
                IP: {{log.ip}}
            </li>
            <li class="list-group-item">
                Sistema Operativo: {{log.OS}}
            </li>
            <li class="list-group-item">
                Región: {{log.region}}
            </li>
            <li class="list-group-item">
                Latitud: {{log.latitud}}
            </li>
            <li class="list-group-item">
                Longitud: {{log.longitud}}
            </li>
            <li class="list-group-item">
                Respuesta del sistema: {{log.respuesta_del_sistema}}
            </li>
            <li class="list-group-item">
                Bloque de data: {{log.bloque}}
            </li>
        </ul>
    </div> -->
    <div class="table-responsive text-center">
            <table class="table table-bordered table-hover  text-center overflow-x:auto">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">IP</th>
                <th scope="col">OS</th>
                <th scope="col">Buscador</th>
                <th scope="col">Version</th>
                <th scope="col">Localización</th>
                <th scope="col">Detalle</th>
                <th scope="col">Observacion</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(trazo, index) in trazabilidad" :key="index" >
                <th scope="row">{{ index + 1}}</th>
                <td>{{ trazo.tc_ip }}</td>
                <td>{{ trazo.tc_os }}</td>
                <td>{{ trazo.tc_browser }}</td>
                <td>{{ trazo.tc_version }}</td>
                <td>{{ trazo.tc_location }}</td>
                <td>{{ trazo.tc_detalle }}</td>
                <td>{{ trazo.tc_observacion }}</td>
                </tr>
            </tbody>
            
            </table>
        </div>
    <div class="column">
        
        <img :src="require('@/assets/estadistica.png')" class="card-img-top mx-auto mt-2 " style="width:9rem;">
    </div>
  </div>
</template>

<script>
import {onMounted, ref } from 'vue';
import axios from 'axios'
import {useAuthStore} from '../stores/auth'
export default {
    setup(){
        const authStore=useAuthStore();
        const trazabilidad=ref([]);
        const actualizarTrazabilidad= async()=>{
            try {
                    if(authStore.log){
                        const response = await axios.get(`/api/getTrazabilidad_clienteByIdApi/${authStore.log.tra_idapi}`);
                        trazabilidad.value = response.data;

                    }
                }
             catch (error) {
                console.error('Error fetching trazabilidad:', error);
            }
            

        }
        
         onMounted(async () => {
            actualizarTrazabilidad();
         });
        return{
            authStore,
            log:authStore.getLog,
            trazabilidad,
            actualizarTrazabilidad
        }
    }
}
</script>

<style>

</style>